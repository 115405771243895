import React, { useState } from "react"
import _ from "lodash"
import {
  InstantSearch,
  Index,
  ScrollTo,
  SearchBox,
  Pagination,
  Stats,
  Menu,
  connectHits,
} from "react-instantsearch-dom"
import algoliasearch from "algoliasearch/lite"
import "instantsearch.css/themes/satellite.css"
import { Grid, Typography } from "@mui/material"
import AlgoliaArticleCard from "../ArticleCard/AlgoliaArticleCard"
import AlgoliaNewsFeedCard from "../NewsFeedCard/AlgoliaNewsFeedCard"
import PageTitle from "../PageTitle/PageTitle"
import "./searchPageComponent.scss"

const SearchPageComponent = ({ searchValue }) => {
  const [query, setQuery] = useState("")
  const [category, setCategory] = useState("all")
  const searchClient = algoliasearch(
    process.env.GATSBY_ALGOLIA_APP_ID,
    process.env.GATSBY_ALGOLIA_SEARCH_KEY
  )

  const changeCategory = (category) => {
    setCategory(category)
  }

  return (
    <>
      <InstantSearch
        indexName="CassandraResources"
        searchClient={searchClient}
        onSearchStateChange={({ query }) => setQuery(query)}
      >
        <ScrollTo className="scrollTo">
          <Grid className="categoryContainer">
            <Typography
              variant="h6"
              className="categoryLink"
              onClick={() => changeCategory("all")}
            >
              All
            </Typography>
            <Typography
              variant="h6"
              className="categoryLink"
              onClick={() => changeCategory("articles")}
            >
              Articles
            </Typography>
            <Typography
              variant="h6"
              className="categoryLink"
              onClick={() => changeCategory("news")}
            >
              News
            </Typography>
            <Typography
              variant="h6"
              className="categoryLink"
              onClick={() => changeCategory("jobs")}
            >
              Jobs
            </Typography>
          </Grid>
          <SearchBox
            defaultRefinement={searchValue}
            translations={{
              placeholder: "Search Cassandra.Link",
            }}
          />

          {category !== "news" && category !== "jobs" && (
            <Index indexName="CassandraResources">
              <ScrollTo>
                <Grid className="filterTags">
                  <Menu limit={6} attribute="tags" showMore={true} />
                </Grid>

                <PageTitle title="Articles" />
                <Stats />
                <CustomHits />
                <Pagination padding={1} />
              </ScrollTo>
            </Index>
          )}

          {category !== "articles" && category !== "jobs" && (
            <Index indexName="CassandraFeeds">
              <ScrollTo>
                <PageTitle title="News" />
                <Stats />
                <CustomHits />
                <Pagination padding={1} />
              </ScrollTo>
            </Index>
          )}

          {category !== "articles" && category !== "news" && (
            <Index indexName="cassandra_job_posts">
              {category === "jobs" && (
                <Grid className="filterTags">
                  <Menu limit={6} attribute="tags" showMore={true} />
                </Grid>
              )}
              <ScrollTo>
                <PageTitle title="Jobs" />
                <Stats />
                <CustomHits />
                <Pagination padding={1} />
              </ScrollTo>
            </Index>
          )}
        </ScrollTo>
      </InstantSearch>
    </>
  )
}

const Hits = ({ hits }) => (
  <Grid container spacing={2}>
    {hits.map((hit) =>
      hit.tags ? (
        <Grid item md={4}>
          <AlgoliaArticleCard data={hit} />
        </Grid>
      ) : (
        !hit.tags && (
          <Grid item md={4}>
            <AlgoliaNewsFeedCard data={hit} />
          </Grid>
        )
      )
    )}
  </Grid>
)

const CustomHits = connectHits(Hits)

export default SearchPageComponent
