import React from "react"
import { Box, Container, Grid } from "@mui/material"
import SearchPageComponent from "../components/search/searchPageComponent"
import Layout from "../components/Layout/Layout"
import { useSearchValueContext } from "../context/SearchValueContext"
import { Helmet } from "react-helmet"

const SearchPage = () => {
  const { searchValue } = useSearchValueContext()
  return (
    <Layout>
      <Helmet>
        <title>Search - Cassandra Link</title>
        <meta
  name="description"
  content="Search for Cassandra resources on Cassandra Link - Your Source for Cassandra Resources"
/>
        <meta property="og:title" content="Cassandra Link - Training" />
        <meta
  property="og:description"
  content="Search for Cassandra resources on Cassandra Link - Your Source for Cassandra Resources"
/>
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://cassandra.link/" />
        <meta property="og:image" content={"../../images/Logo.svg"} />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://cassandra.link/",
            "@type": "WebPage",
            name: "Cassandra Link - Your Source for Cassandra Resources",
            keywords: "Cassandra Link, Search, Cassandra",
            author: {
              "@type": "Organization",
              name: "Cassandra Link",
            },
          })}
        </script>

        {/* Other meta tags you may consider adding */}
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta name="robots" content="index, follow" />
        <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
        {/* Twitter Card */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={"Cassandra Link"} />
        {/*  */}
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://cassandra.link/search" />
        <meta property="og:image" content={"../../images/Logo.svg"} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Cassandra Link - Search" />
        <meta
          name="twitter:description"
          content="Search for Cassandra resources on Cassandra Link - Your Source for Cassandra Resources"
        />
        <meta name="twitter:image" content={"../../images/Logo.svg"} />
      </Helmet>
      <Container maxWidth="lg">
        <Box
          className="articlesContainer"
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            maxWidth: 1600,
            marginX: "auto",
          }}
        >
          <SearchPageComponent searchValue={searchValue} />
        </Box>
      </Container>
    </Layout>
  )
}

export default SearchPage
